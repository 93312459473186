import CameraArlo from "@/assets/img/securitas/Securitas_products/alto/Producto_Camara-Arlo.png";
import CameraPro from "@/assets/img/securitas/Securitas_products/alto/Producto_Camera_Pro.png";
import Cerradura from "@/assets/img/securitas/Securitas_products/alto/Producto_Cerradura.png";
import Detector1 from "@/assets/img/securitas/Securitas_products/alto/Producto_Dector3.png";
import Detector2 from "@/assets/img/securitas/Securitas_products/alto/Producto_Detector.png";
import Detector3 from "@/assets/img/securitas/Securitas_products/alto/Producto_Detector_2.png";
import Fotodetector from "@/assets/img/securitas/Securitas_products/alto/Producto_Fotodetector.png";
import Llaves from "@/assets/img/securitas/Securitas_products/alto/Producto_Llaves.png";
import Mando from "@/assets/img/securitas/Securitas_products/alto/Producto_Mando.png";
import Panel1 from "@/assets/img/securitas/Securitas_products/alto/Producto_Panel.png";
// import Placas from "@/assets/img/securitas/Securitas_products/alto/Producto_Placas.png";
import Pulsador from "@/assets/img/securitas/Securitas_products/alto/Producto_Pulsador.png";
import Sentimel from "@/assets/img/securitas/Securitas_products/alto/Producto_Sentimel.png";
import Zerovision from "@/assets/img/securitas/Securitas_products/alto/Producto_Zerovision.png";
import Unidad from "@/assets/img/securitas/Securitas_products/alto/Producto_unidad.png";
import Panel2 from "@/assets/img/securitas/Securitas_products/alto/Proudcto_Panel_exterior.png";
import BodegonA from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_A.png";
import BodegonB from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_B.png";
import BodegonC from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_C.png";
import BodegonD from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_D.png";
import BodegonE from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_E.png";
import BodegonF2 from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_F2.png";
import BodegonF from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_F.png";
import BodegonG from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_G.png";
import BodegonH from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_H.png";
import BodegonI from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_I.png";
import PlacaI from "@/assets/img/securitas/Securitas_products/placa/placa.png";

import { SECURITAS_MODELS } from "@/utils";

const getSecuritaProductsByProfile = (profile) => {
  const alto_detalle = [
    {
      aux: "Arlo Security Pro",
      fileName: "Final_10.png",
      name: "CAMARA",
      image: CameraArlo,
    },
    {
      aux: "Arlo Security Pro",
      fileName: "Final_9.png",
      name: "CAMARA",
      image: CameraPro,
    },
    {
      aux: "Inteligente",
      fileName: "Final_8.png",
      name: "CERRADURA",
      image: Cerradura,
    },
    {
      aux: "de acceso",
      fileName: "Final_5.png",
      name: "DETECTOR",
      image: Detector1,
    },
    {
      aux: "de incendios",
      fileName: "Final_6.png",
      name: "DETECTOR",
      image: Detector2,
    },
    {
      aux: "Perimetral",
      fileName: "Final_7.png",
      name: "DETECTOR",
      image: Detector3,
    },
    {
      aux: "",
      fileName: "Final_4.png",
      name: "FOTODETECTOR",
      image: Fotodetector,
    },
    {
      aux: "Inteligentes",
      fileName: "Final_3.png",
      name: "LLAVES",
      image: Llaves,
    },
    {
      aux: "a distancia",
      fileName: "Final_2.png",
      name: "MANDO",
      image: Mando,
    },
    {
      aux: "de control",
      fileName: "Final_1.png",
      name: "PANEL",
      image: Panel1,
    },
    {
      aux: "de control exterior",
      fileName: "Final_15.png",
      name: "PANEL",
      image: Panel2,
    },
    // {
    //   aux: "disuasorias",
    //   fileName: "Final_10.png",
    //   name: "PLACAS",
    //   image: Placas,
    // },
    {
      aux: "SOS",
      fileName: "Final_14.png",
      name: "PULSADOR",
      image: Pulsador,
    },
    {
      aux: "V2",
      fileName: "Final_13.png",
      name: "SENTINEL",
      image: Sentimel,
    },
    {
      aux: "central",
      fileName: "Final_12.png",
      name: "UNIDAD",
      image: Unidad,
    },
    {
      aux: "",
      fileName: "Final_11.png",
      name: "ZEROVISION",
      image: Zerovision,
    },
  ];

  const bodegon = [
    {
      fileName: "Frame_2.png",
      name: "BODEGÓN A",
      image: BodegonA,
    },
    {
      fileName: "Frame_3.png",
      name: "BODEGÓN B",
      image: BodegonB,
    },
    {
      fileName: "Frame_4.png",
      name: "BODEGÓN C",
      image: BodegonC,
    },
    {
      fileName: "Frame_5.png",
      name: "BODEGÓN D",
      image: BodegonD,
    },
    {
      fileName: "Frame_6.png",
      name: "BODEGÓN E",
      image: BodegonE,
    },
    {
      fileName: "Frame_7.png",
      name: "BODEGÓN F",
      image: BodegonF,
    },
    {
      fileName: "Frame_8.png",
      name: "BODEGÓN F2",
      image: BodegonF2,
    },
    {
      fileName: "Frame_9.png",
      name: "BODEGÓN G",
      image: BodegonG,
    },
    {
      fileName: "Frame_10.png",
      name: "BODEGÓN H",
      image: BodegonH,
    },
    {
      fileName: "Frame_11.png",
      name: "BODEGÓN I",
      image: BodegonI,
    },
  ];
  switch (profile) {
    case SECURITAS_MODELS.ALTO_DETALLE:
      return alto_detalle;
    case SECURITAS_MODELS.BODEGON:
      return bodegon;
    case SECURITAS_MODELS.CARTELES_DISUASORIOS:
      return [
        {
          aux: "disuasorias",
          fileName: "placa2020.webp",
          name: "Placas",
          image: PlacaI,
        },
      ];

    default:
      return [];
  }
};
export default getSecuritaProductsByProfile;
