import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import React from "react";

import Card1 from "@/assets/img/fundador/Card_1.png";
import Card2 from "@/assets/img/fundador/Card_2.png";
import Card4 from "@/assets/img/fundador/Card_3.png";
import Card5 from "@/assets/img/fundador/edicion-image-home.png";
import Card3 from "@/assets/img/fundador/momentos-image-home.png";

import CardApp from "@/components/CardApp/CardApp";

import { setModel, setProfile } from "@/redux/store";

import { FUNDADOR_MODELS, SILBO_PROFILES } from "@/utils";

const FundadorHomeContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <Row className="row-container mb-3">
        <Col xs={4}>
          <CardApp
            available={true}
            image={Card1}
            onClick={() => {
              dispatch(setProfile(SILBO_PROFILES.SILBO_BRAND_SPECIALIST));
              navigate("/general");
            }}
            text="FUNDADOR Copywriter"
          />
        </Col>
        <Col xs={4}>
          <CardApp
            available={true}
            image={Card2}
            onClick={() => {
              navigate("/home/personajes");
            }}
            text="Personajes FUNDADOR"
          />
        </Col>
        <Col xs={4}>
          <CardApp
            available={true}
            image={Card3}
            onClick={() => {
              dispatch(setModel(FUNDADOR_MODELS.MOMENTOS));
              navigate("/home/momentos");
            }}
            text="Momentos de Consumo"
          />
        </Col>
      </Row>
      <Row className="row-container mb-3">
        <Col xs={8}>
          <CardApp
            available={false}
            image={Card4}
            onClick={() => {
              dispatch(setProfile(SILBO_PROFILES.SILBO_AD_CREATOR));
              navigate(SILBO_PROFILES.SILBO_AD_CREATOR, { replace: true });
            }}
            text="FUNDADOR Ad Creator"
          />
        </Col>
        <Col xs={4}>
          <CardApp
            available={false}
            image={Card5}
            onClick={() => {
              navigate("/home/personajes");
            }}
            text="Edición de imagen"
          />
        </Col>
      </Row>
    </>
  );
};
export default FundadorHomeContent;
